<template>
  <div class="height1">
    <bread></bread>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="month" value-format="yyyy-MM" size="small" placeholder="选择日期" @change="Search"> </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-select v-model="searchForm.isAll" placeholder="请选择" size="small" @change="Search">
                  <el-option label="全部" value="0"></el-option>
                  <el-option label="充值" value="1"></el-option>
                  <el-option label="提现" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
                <el-button type="warning" size="small" @click="RechargeBtn()" plain>充值</el-button>
                <el-button type="success" size="small" @click="WithdrawalBtn()" plain>提现</el-button>
                <el-button type="warning" size="small" @click="transferBtn()" plain>转账</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe height="calc(100% - 90px)" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }">
          <el-table-column prop="createTime" label="时间">
            <template slot-scope="scope"> {{ scope.row.createTime | timefilters }} </template>
          </el-table-column>
          <el-table-column prop="purpose" label="操作类型" align="center">
            <template slot-scope="scope">
              <el-tag type="warning" size="small" v-if="scope.row.purpose == 'recharge'">充值</el-tag>
              <el-tag type="success" size="small" v-if="scope.row.purpose == 'withdrawal'">提现</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="金额（元）" align="center"> </el-table-column>
          <el-table-column prop="paymentMethod" label="充值/提现至" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.paymentMethod == '1'">微信</span>
              <span v-if="scope.row.paymentMethod == '2'">支付宝</span>
              <span v-if="scope.row.paymentMethod == '0'">余额</span>
            </template>
          </el-table-column>
          <el-table-column prop="beforeBalance" label="操作前金额（元）" align="center"> </el-table-column>
          <el-table-column prop="afterBalance" label="操作后金额（元）" align="center"> </el-table-column>
          <el-table-column prop="state" label="状态" align="center">
            <template slot-scope="scope">
              <el-tag effect="dark" size="small" type="success" v-if="scope.row.state == '1'"><i class="el-icon-success"></i> 完成</el-tag>
              <el-tag effect="dark" size="small" type="warning" v-if="scope.row.state == '0'"><i class="el-icon-warning"></i> 待完成</el-tag>
              <el-tag effect="dark" size="small" type="danger" v-if="scope.row.state == '-1'"><i class="el-icon-error"></i> 已拒绝</el-tag>
              <el-tag effect="dark" size="small" type="info" v-if="scope.row.state == '-2'"><i class="el-icon-remove"></i> 已取消</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="operator.userName" label="操作人" align="center"> </el-table-column>
          <el-table-column prop="operator.account" label="操作账号" align="center"> </el-table-column>
        </el-table>
        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <!--    充值    -->
    <el-dialog title="充值" :visible.sync="RechargeDialog" :before-close="handleClose">
      <el-form class="dialog-form" :model="RechargeForm" ref="RechargeForm" :label-width="formLabelWidth" :rules="rules">
        <el-form-item label="账户可用余额" prop="userName">
          <span class="Balance">{{ assetsBalance }}</span> 元
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="充值金额" prop="amount">
          <!-- <el-input v-model="RechargeForm.amount" autocomplete="off"></el-input> -->
          <el-input-number v-model="RechargeForm.amount" :precision="2" :step="1" :min="0.01" controls-position="right"  @change="amountChange"/>
        </el-form-item>
        <el-form-item label="支付方式">
          <el-radio-group style="display: flex; margin-top: 11px" v-model="RechargeForm.type">
            <el-radio :label="1">微信</el-radio>
            <el-radio :label="2" :disabled="RechargeForm.amount>2000">支付宝<span style="color: red; font-size: 12px"> 暂不支持金额超过2000</span></el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            RechargeDialog = false
            getList()
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="RechargeSave('RechargeForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!--    提现    -->
    <el-dialog title="提现" :visible.sync="WithdrawalDialog">
      <el-form class="dialog-form" :model="WithdrawalForm" ref="WithdrawalForm" :label-width="formLabelWidth" :rules="rules">
        <el-form-item label="账户可用余额" prop="userName">
          <span class="Balance">{{ assetsBalance }}</span> 元
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="提现金额" prop="amount">
          <!-- <el-input v-model="WithdrawalForm.amount" autocomplete="off" @blur="calcBtn()"></el-input> -->
          <el-input-number v-model="WithdrawalForm.amount" :precision="2" :step="1" :min="0.01" controls-position="right" @blur="calcBtn()" />
        </el-form-item>
        <el-form-item label="提现手续费">
          <span class="">{{ serviceCharge }}</span> 元
        </el-form-item>
        <el-form-item label="实到金额">
          <span class="">{{ actualCost }}</span> 元
        </el-form-item>
        <el-form-item label="提现至" prop="bankCardId">
          <el-select v-model="WithdrawalForm.bankCardId" placeholder="请选择">
            <el-option v-for="item in bankcardData" :key="item.id" :label="`${item.bankName}【${item.cardNo}】`" :value="item.id">
              <span style="float: left">{{ item.bankName }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cardNo }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提现方式">
          <el-radio-group v-model="WithdrawalForm.remittanceWay" size="mini">
            <el-radio-button label="0">普通</el-radio-button>
            <el-radio-button label="1">加急</el-radio-button>
            <el-radio-button label="2">跨行快汇合</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="短信通知手机号" prop="gatheringPhone">
          <el-input v-model="WithdrawalForm.gatheringPhone" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="WithdrawalDialog = false">取 消</el-button>
        <el-button type="primary" @click="WithdrawalSave('WithdrawalForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!--    支付    -->
    <el-dialog title="支付" :visible.sync="PaymentDialog" :before-close="handleClose">
      <div class="pay-top">
        <ul class="">
          <li class="payItem clearfix">
            <p>收款账户：</p>
            <span>河南千越医疗科技有限公司</span>
          </li>
          <li class="payItem clearfix">
            <p>收款日期：</p>
            <span>{{ gettime }}</span>
          </li>
          <li class="payItem clearfix">
            <p>应付金额：</p>
            <span class="payNum">{{ payMoney }}元</span>
          </li>
        </ul>
      </div>
      <el-divider></el-divider>
      <div class="pay-bg" v-if="RechargeForm.type == 1">
        <img :src="qRcodeUrl" alt="" />
      </div>
      <div class="flex-center" v-else>
        <iframe :srcdoc="qRcodeUrl" frameborder="no" border="0" marginwidth="0" marginheight="0" scrolling="no" width="200" height="200" style="overflow: hidden"> </iframe>
      </div>
    </el-dialog>
    <!-- 转账 -->
    <!--    转账    -->
    <el-dialog title="转账" :visible.sync="transferDialog" width="500px">
      <el-form class="dialog-form" :model="TransferForm" ref="TransferFormref" label-width="100px" :rules="rules">
        <el-form-item label="账户可用余额" prop="userName">
          <span class="Balance">{{ assetsBalance }}</span> 元
        </el-form-item>
        <el-form-item label="转账至" prop="type">
          <el-radio v-model="TransferForm.type" label="1">机构</el-radio>
          <el-radio v-model="TransferForm.type" label="2">个人</el-radio>
        </el-form-item>
        <el-form-item label="机构" style="width: 80%" v-if="TransferForm.type == '1'" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
          <el-input v-model="orgname" placeholder="选择机构" class="input-with-select">
            <template #append>
              <el-button @click="gosearchOrg" icon="el-icon-search">
                <!-- <el-icon icon="el-icon-search" :size="20" @click="gosearchOrg"></el-icon> -->
              </el-button>
            </template>
          </el-input>
          <el-scrollbar v-if="serchList.length != 0" min-height="300px" style="width: 100%">
            <div v-for="(item, index) in serchList" :key="index" class="scrollbar-demo-item" @click="goChecked(item)">{{ item.name }}</div>
          </el-scrollbar>
          <div v-else class="scrollbar-demo-item">暂无更多内容</div>
        </el-form-item>
        <el-form-item label="个人" prop="amount" style="width: 80%" v-else>
          <el-input v-model="namePeople" placeholder="选择个人" class="input-with-select">
            <template #append>
              <el-button @click="gosearchPeople" icon="el-icon-search">
                <!-- <el-icon :size="20" ></el-icon> -->
              </el-button>
            </template>
          </el-input>
          <el-scrollbar v-if="serchList.length != 0" min-height="300px" style="width: 100%">
            <div v-for="(item, index) in serchList" :key="index" class="scrollbar-demo-item" @click="goChecked(item)">{{ item.userName }}</div>
          </el-scrollbar>
          <div v-else class="scrollbar-demo-item">暂无更多内容</div>
        </el-form-item>
        <el-form-item label="机构名称" v-if="TransferForm.type == '1'">
          <span class="">{{ TransferForm.searchName }}</span>
        </el-form-item>
        <el-form-item label="个人姓名" v-else>
          <span class="">{{ TransferForm.searchName }}</span>
        </el-form-item>
        <el-form-item label="转账金额（元）" prop="amount">
          <el-input-number v-model="TransferForm.amount" :precision="2" :step="1" :min="0.01" controls-position="right" />
        </el-form-item>
        <el-form-item label="备注" style="width: 90%">
          <el-input v-model="TransferForm.remake" type="textarea" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="transferDialog = false">取 消</el-button>
          <el-button type="primary" @click="TransferSave('TransferFormref')">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import bread from '../../../components/common/breadcrumb'
import { Distributor } from '../../../components/HospitalDomain/Distributor'
import { WeChatPay } from '../../../components/HospitalDomain/WeChatPay'
import { Bankcard } from '../../../components/HospitalDomain/Bankcard'
import { RenewalUpgrade } from '../../../components/HospitalDomain/RenewalUpgrade'
var timer = ''
export default {
  name: 'Withdrawal',
  components: {
    bread,
  },
  data() {
    var distributor = new Distributor(this.TokenClient, this.Services.Authorization)
    var weChat = new WeChatPay(this.TokenClient, this.Services.Authorization)
    var bankcard = new Bankcard(this.TokenClient, this.Services.Authorization)
    var renewalUpgrade = new RenewalUpgrade(this.TokenClient, this.Services.Authorization)
    return {
      DistributorDomain: distributor,
      WeChatPayDomain: weChat,
      RenewalDomain: renewalUpgrade,
      Bankcard: bankcard,
      tableData: [],
      searchForm: {
        isAll: '0',
        statrTime: '',
        endTime: '',
        keyWord: '',
        datepicker: '',
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
      userId: JSON.parse(sessionStorage.getItem('userInfo')).id,
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      DataTotal: 0,
      formLabelWidth: '120px',
      RechargeDialog: false,
      RechargeForm: {
        organizationId: 0,
        amount: '',
        type: 1,
        bankCardId: '',
      },
      assetsBalance: '',
      payMoney: 0,
      PaymentDialog: false,
      qRcodeUrl: '',
      gettime: '',
      weChatInfo: {},
      WithdrawalDialog: false,
      WithdrawalForm: {
        remittancePurpose: '机构提现',
        remittanceRemark: '机构提现',
        remittanceWay: 0,
        gatheringPhone: '',
        amount: 0,
      },
      ToSystemData: {},
      serviceCharge: null,
      actualCost: null,
      bankcardData: [],
      rules: {
        amount: [
          // { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: '小数点后保留两位', trigger: 'blur' },
          { required: true, message: '请输入金额', trigger: 'blur' },
        ],
        bankCardId: [{ required: true, message: '请选择银行卡', trigger: 'change' }],
        gatheringPhone: [{ pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: '请输入合法手机号/电话号', trigger: 'blur' }],
      },
      transferDialog: false,
      TransferForm: {
        type: '1',
        amount: '0.01',
        searchName: '',
      },
      serchList: [],
      MyBalanceDetail: {},
      serchId: '',
      namePeople: '',
      orgname: '',
    }
  },
  mounted() {
    this.getList()
    this.getCurrentTime()
    this.getbankcard()
    this.getBalance()
  },
  methods: {
    amountChange() {
      if (this.RechargeForm.amount > 2000) {
        this.RechargeForm.type = 1;
      }
    },
    gosearchPeople() {
      var _this = this
      _this.Bankcard.getPayeeUserInfo(
        _this.namePeople,
        function (data) {
          _this.serchList = data.data
        },
        function (error) {
          if (error.status == '404') {
            _this.serchList = []
          }
        }
      )
    },
    gosearchOrg() {
      var _this = this
      _this.Bankcard.getOrganizationName(
        _this.orgname,
        function (data) {
          _this.serchList = data.data
        },
        function (error) {
          if (error.status == '404') {
            _this.serchList = []
          }
        }
      )
    },
    getBalance() {
      var _this = this
      _this.DistributorDomain.BalanceDetail(
        _this.organizationId,
        function (data) {
          _this.assetsBalance = data.data.availableBalance
        },
        function (error) {
          console.log(error)
        }
      )
    },
    handleClose(done) {
      clearInterval(timer)
      this.PaymentDialog = false
      this.RechargeDialog = false
      this.getList()
    },
    transferBtn() {
      var _this = this
      _this.transferDialog = true
      _this.TransferForm.searchName = ''
      _this.serchList = []
    },
    getCurrentTime() {
      //获取当前时间并打印
      var _this = this
      let yy = new Date().getFullYear()
      let mm = new Date().getMonth() + 1
      let dd = new Date().getDate()
      _this.gettime = yy + '/' + mm + '/' + dd
    },
    SearchInputKeydown(event) {
      if (event.keyCode == 13) {
      }
    },
    Search(event) {
      this.PageIndex = 1
      this.getList()
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.getList()
    },
    getList() {
      var _this = this
      var item = _this.searchForm
      /*if( _this.searchForm.datepicker != ''){
                item.endTime = _this.searchForm.datepicker[1] +' 23:59:59';
            }*/
      _this.DistributorDomain.WithdrawalList(
        item.datepicker,
        item.isAll,
        _this.PageIndex,
        function (data) {
          _this.tableData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.DataTotal = data.data.dataTotal
        },
        function (error) {
          console.log(error)
        }
      )
    },
    RechargeBtn() {
      var _this = this
      _this.RenewalDomain.Verifivation(
        [_this.userId],
        function (res) {
          _this.RechargeDialog = true
          _this.RechargeForm.organizationId = _this.organizationId
          _this.RechargeForm.amount = ''
          _this.RechargeForm.type = 1
          _this.DistributorDomain.BalanceDetail(
            _this.organizationId,
            function (data) {
              _this.assetsBalance = data.data.availableBalance
            },
            function (error) {
              console.log(error)
            }
          )
        },
        function (error) {
          var users = []
          var name = ''
          var err = error.data
          err.forEach((item) => {
            users = _this.UserData.filter((i) => i.id === item)
            users.forEach((k) => {
              name = name + k.userName + ' '
            })
          })
          _this.$confirm('员工：' + name + '存在未支付订单', '提示', {
            confirmButtonText: '我知道了',
            type: 'warning',
            showCancelButton: false,
          })
        }
      )
    },
    RechargeSave(formName) {
      var _this = this
      var item = _this.RechargeForm
      item.attach = 'recharge'
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.DistributorDomain.Recharge(
            item,
            function (data) {
              _this.RechargeDialog = false
                _this.payMoney = _this.RechargeForm.amount
              if (_this.RechargeForm.type == 1) {
                _this.qRcodeUrl = _this.Services.Authorization + data.data.qRcodeUrl
                _this.weChatInfo = data.data
              } else {
                _this.weChatInfo.orderNo = data.data.orderNo
                _this.qRcodeUrl = data.data.alipayData;
                _this.weChatInfo.attach = 'recharge'
                // var newWindow = window.open('', '_blank')
                // newWindow.document.write(data.data.alipayData)
              }
                _this.PaymentDialog = true
              var isSuccess = false
              timer = window.setInterval(() => {
                setTimeout(function () {
                  _this.WeChatPayDomain.VerificationSuccessPay(
                    _this.weChatInfo,
                    function (data2) {
                      isSuccess = data2.data.isSuccess
                      if (isSuccess) {
                        clearInterval(timer)
                        _this.$message({
                          type: 'success',
                          message: '充值成功!',
                        })
                        _this.PaymentDialog = false
                        _this.RechargeDialog = false
                        _this.getList()
                      }
                    },
                    function (error) {
                      console.log(error)
                    }
                  )
                }, 0)
              }, 5000)
              setTimeout(() => {
                if (!isSuccess) {
                  clearInterval(timer)
                  if (_this.PaymentDialog) {
                    _this.$message({
                      type: 'error',
                      message: '支付超时!',
                    })
                    _this.PaymentDialog = false
                    _this.RechargeDialog = false
                    _this.getList()
                  }
                }
              }, 300000)
            },
            function (error) {
              console.log(error)
            }
          )
        }
      })
    },
    getPayState() {
      var _this = this
      _this.WeChatPayDomain.VerificationSuccessPay(
        _this.weChatInfo,
        function (data) {
          clearInterval(timer)
          _this.$message({
            type: 'success',
            message: '充值成功!',
          })
          _this.PaymentDialog = false
          _this.RechargeDialog = false
          _this.getList()
        },
        function (error) {
          console.log(error)
        }
      )
    },
    WithdrawalBtn() {
      var _this = this
      this.WithdrawalDialog = true
      _this.DistributorDomain.BalanceDetail(
        _this.organizationId,
        function (data) {
          _this.assetsBalance = data.data.availableBalance
          _this.WithdrawalForm.remittancePurpose = '机构提现'
          _this.WithdrawalForm.remittanceRemark = '机构提现'
          _this.WithdrawalForm.remittanceWay = '0'
          _this.WithdrawalForm.gatheringPhone = ''
        },
        function (error) {
          console.log(error)
        }
      )
      // this.$router.push('/Finance/PayPage')
    },
    calcBtn() {
      var _this = this
      var item = {
        organizationId: _this.organizationId,
        amount: _this.WithdrawalForm.amount,
        beforeBalance: _this.assetsBalance,
      }
      _this.DistributorDomain.CalcWithdrawal(
        item,
        function (data) {
          _this.ToSystemData = data.data
          _this.serviceCharge = data.data.serviceCharge
          _this.actualCost = data.data.actualCost
          console.log(data.data)
        },
        function (error) {
          console.log(error)
        }
      )
    },
    WithdrawalSave(formName) {
      var _this = this
      _this.$refs[formName].validate((res) => {
        if (res) {
          _this.ToSystemData.withdrawalTo = 'system'
          _this.ToSystemData.operatorId = _this.userId
          _this.ToSystemData.bankCardId = _this.WithdrawalForm.bankCardId
          _this.ToSystemData.remittancePurpose = '机构提现'
          _this.ToSystemData.remittanceRemark = '机构提现'
          _this.ToSystemData.remittanceWay = parseInt(_this.WithdrawalForm.remittanceWay)
          _this.ToSystemData.gatheringPhone = _this.WithdrawalForm.gatheringPhone
          _this.DistributorDomain.ToSystem(
            _this.ToSystemData,
            function (data) {
              _this.$message({
                message: '审核通过后第二个工作日到账',
                type: 'success',
              })
              _this.WithdrawalDialog = false
              _this.getList()
            },
            function (err) {
              var jsonObj = JSON.parse(err.msg)
              if (jsonObj.code == '400') {
                _this.$message({
                  type: 'error',
                  message: jsonObj.msg,
                })
              }
            }
          )
        } else {
          return false
        }
      })
    },
    goChecked(e) {
      this.serchId = e.id
      this.TransferForm.searchName = e.name ? e.name : e.userName
    },
    getbankcard() {
      var _this = this
      _this.bankcardData = []
      _this.Bankcard.bankcardList(
        _this.organizationId,
        function (data) {
          _this.bankcardData = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
    TransferSave(formName) {
      var _this = this
      _this.$refs[formName].validate((res) => {
        if (res) {
          if (_this.TransferForm.amount > _this.assetsBalance) {
            _this.$message.error('余额不足!')
            return
          }
          if (!_this.serchId) {
            _this.$message.error('暂未选择转款对象!')
            return
          }
          let parmas = {}
          parmas.PayWay = 0
          parmas.Amount = _this.TransferForm.amount
          parmas.TransferOrganizationId = _this.organizationId
          if (_this.TransferForm.type == 1) {
            parmas.type = 3
            parmas.PayeeOrganizationId = _this.serchId
          } else {
            parmas.type = 2
            parmas.PayeeUserId = _this.serchId
          }
          _this.Bankcard.toBalanceTransfer(
            parmas,
            function (data) {
              _this.$message({
                message: '转账成功',
                type: 'success',
              })
              _this.transferDialog = false
              _this.getList()
              console.log(data.data)
            },
            function (err) {
              var jsonObj = JSON.parse(err.msg)
              if (jsonObj.code == '400') {
                _this.$message({
                  type: 'error',
                  message: jsonObj.msg,
                })
              }
            }
          )
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style scoped>
.Balance {
  font-size: 24px;
  font-weight: 600;
}

.pay-top {
  position: relative;
}

.payItem {
  line-height: 2;
}

.payItem p {
  float: left;
}

.payItem span {
  float: right;
}

.payNum {
  color: red;
  font-size: 24px;
}

.pay-bg {
  position: relative;
  margin: 20px auto;
  width: 350px;
  height: 436px;
  background: url('../../../assets/img/wx_pay.jpg') top center no-repeat;
  background-size: cover;
}

.pay-bg img {
  position: absolute;
  width: 160px;
  top: 140px;
  left: 100px;
}
.scrollbar-demo-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin: 10px 0;
  text-align: center;
  border-radius: 4px;
  background: #e8f3fe;
  color: #7dbcfc;
  cursor: pointer;
}
</style>
